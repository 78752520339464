<template>
  <div class="bonus-form">
      <div style="text-align:center;"  class="bonus-form-field header">
          <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Frosman-market_375x375.png?alt=media&token=b055241e-d401-4ae2-9c14-178553610dc2" alt="">
          <h2>שליחת טופס בונוס {{new Date().toLocaleString('he')}}</h2>
      </div>
      <div class="bonus-form-field">
          <h1>סניף <span style="color:red;">*</span></h1>
          <el-select style="width:100%;" v-model="values.branche" filterable clearable placeholder="בחירת סניף">
                <el-option
                    v-for="branche in branches_list"
                    :key="branche"
                    :value="branche"
                >
                </el-option>
          </el-select>
      </div>
      <div class="bonus-form-field">
          <h1>ברקוד <span style="color:red;">*</span></h1>
          <el-input v-model="values.barcode" type="number" placeholder="ברקוד" />
      </div>
      <div class="bonus-form-field">
          <h1>שם הפריט <span style="color:red;">*</span></h1>
          <el-input v-model="values.product_name"  placeholder="שם פריט" />
      </div>
      <div class="bonus-form-field">
          <h1> בחירת סוג הכמות <span style="color:red;">*</span></h1>
          <el-select style="width:100%;" v-model="values.qnt_type" class="m-2" placeholder="בחירת סוג הכמות" size="large">
            <el-option
                v-for="type in types_options"
                :key="type"
                :value="type"
            >
            </el-option>
         </el-select>
      </div>
      <div v-if="values.qnt_type" class="bonus-form-field">
          <h1> כמות ב{{values.qnt_type}} <span style="color:red;">*</span></h1>
          <el-input v-if="values.qnt_type.includes('ק')" v-model="values.qnt" type="number" :min="0" :step="0.1" />
          <el-input v-else v-model="values.qnt" type="number" :min="0" />
      </div>
      <div class="bonus-form-field">
        <h1>  בחירת סוג הבונוס <span style="color:red;">*</span></h1>
        <el-select style="width:100%;" v-model="values.bonus_type" class="m-2" placeholder="בחירת סוג הבונוס" size="large">
            <el-option
                v-for="type in types_options"
                :key="type"
                :value="type"
            >
            </el-option>
         </el-select>
      </div>
      <div v-if="values.bonus_type" class="bonus-form-field">
          <h1> כמות בונוס ב{{values.bonus_type}} <span style="color:red;">*</span></h1>
          <el-input v-if="values.bonus_type.includes('ק')" v-model="values.bonus_qnt" type="number" :min="0" :step="0.1" />
          <el-input v-else v-model="values.bonus_qnt" type="number" :min="0" />
      </div>
      <div class="bonus-form-field">
        <el-button v-if="!edit_record" @click="handle_submit" style="width:100%; margin-top:10px" type="success">שליחה</el-button>
        <el-button v-if="edit_record" @click="handle_update" style="width:100%; margin-top:10px" type="success">עדכון</el-button>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {slide_pop_error,alert} from '../../../../../Methods/Msgs'
import {get_branches_from_db} from '../../../../../Methods/rosman_shivuk_funcs'
import {projectFirestore} from '../../../../../firebase/config'



export default {
    props:['edit_record'],
    emits:['update_record'],
    setup(props,{emit}){
        const types_options = ref([
            'מארזים',
            'ק\"ג',
            'יחידות'
        ])
        const branches_list = ref([])
        const error_msg = ref('')
        const values = ref({
            branche:'',
            creates_at:new Date(),
            barcode:'',
            product_name:'',
            qnt_type:'',
            qnt:0,
            bonus_type:'',
            bonus_qnt:0,
            status:'חדש'
        })

        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'branche':
                        if(!value){
                            error_msg.value = 'עליך לבחור סניף'
                            return false
                        }
                        break;
                    case 'barcode':
                        if(!value){
                            error_msg.value = 'עליך להזין ברקוד'
                            return false
                        }
                        break;
                    case 'product_name':
                        if(!value){
                            error_msg.value = 'עליך להזין שם פריט'
                            return false
                        }
                        break;
                    case 'qnt_type':
                        if(!value){
                            error_msg.value = 'עליך לבחור את סוג הכמות'
                            return false
                        }
                        break;
                    case 'qnt':
                        if(value==0){
                            error_msg.value = 'עליך להזין כמות'
                            return false
                        }
                        break;
                    case 'bonus_type':
                        if(!value){
                            error_msg.value = 'עליך לבחור את סוג הבונוס'
                            return false
                        }
                        break;
                    case 'bonus_qnt':
                        if(value==0){
                            error_msg.value = 'עליך להזין כמות בונוס'
                            return false
                        }
                        break;
                }
            }
            return true
        }
        const handle_update = async()=>{
             if(!validation()){
                slide_pop_error(error_msg.value)
             }else{
                await update_record_in_db()
                alert('success','הצלחה',
                'הטופס עודכן בהצלחה')
                .then(()=>{
                    emit('update_record',values.value.status)
                })
             }

        }
        const handle_submit = async()=>{
            if(!validation()){
                slide_pop_error(error_msg.value)
            }else{
                await save_in_db()
                alert('success','הצלחה',
                'הטופס נשלח בהצלחה')
                .then(()=>{
                    reset()
                })
            }
        }
        
        const update_record_in_db = async()=>{
            values.value.qnt = Number(values.value.qnt)
            values.value.bonus_qnt = Number(values.value.bonus_qnt)
            const doc = projectFirestore.collection('Applications').doc('2l5VnaCyL99x0EhM7Gw6')
            .collection('forms').doc(values.value.uid)
            await doc.set(values.value,{merge:true})
        }

        const save_in_db = async()=>{
            values.value.qnt = Number(values.value.qnt)
            values.value.bonus_qnt = Number(values.value.bonus_qnt)
            const doc = projectFirestore.collection('Applications').doc('2l5VnaCyL99x0EhM7Gw6')
            .collection('forms').doc()
            await doc.set({
                ...values.value,
                uid:doc.id
            })
        }

        const reset = ()=>{
            values.value = {
                branche:'',
                creates_at:new Date(),
                barcode:'',
                product_name:'',
                qnt_type:'',
                qnt:0,
                bonus_type:'',
                bonus_qnt:0,
                status:'חדש'
            }
        }

        const init = async()=>{
            branches_list.value = await get_branches_from_db()
            if(props.edit_record){
                values.value = JSON.parse(JSON.stringify(props.edit_record))
                delete values.value.creates_at
            }
        }

        init()
        return{
            values,
            error_msg,
            handle_submit,
            handle_update,
            branches_list,
            types_options
        }
    }
}
</script>

<style scoped>
    .bonus-form{
        padding: 10px;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        height: 95%;
        overflow: hidden;
        overflow-y: auto;
        color: #fff;
        background: var(--secondary);
        border-radius: 10px;
    }
    .bonus-form-field{
        margin-top: 5px;
        padding: 0 5px;
        width: 100%;
        height: fit-content;
    }
    .header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>