<template>
  <div class="new-form">
     <Welcome @next="state='טופס בונוס'"  v-if="!state" />
     <BonusForm v-if="state=='טופס בונוס'"/>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Welcome from './Form/Welcome.vue'
import BonusForm from './Form/BonusForm.vue'
export default {
    components:{Welcome,BonusForm},
    setup(){
    
        const state = ref(null)

        return {state}
    }
}
</script>

<style scoped>
    .new-form{
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    }

</style>