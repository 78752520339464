<template>
  <div class="setting">
      <div class="setting-dialog slide-in-top">
          <div class="setting-dialog-header">
              <h2>{{record.product_name}} - {{record.barcode}}</h2>
              <div @click="handle_close" class="close-btn">
                  X
              </div>
          </div>
          <div class="setting-dialog-content">
            <h1>שינוי סטטוס</h1>
            <el-select v-model="selected_status"  class="m-2" placeholder="בחירת סטטוס" size="large">
                <el-option
                v-for="status in status_options"
                :key="status"
                :value="status"
                >
                </el-option>
            </el-select>
            <el-button @click="handle_update_status" style="margin-top:5px; width:200px" type="success">עדכון</el-button>
          </div>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {alert, slide_pop_error} from '../../../../../Methods/Msgs'
import {projectFirestore} from '../../../../../firebase/config'
export default {
    props:['record'],
    emits:['close','update'],
    setup(props,{emit}){
        const selected_status = ref('')
        const status_options =ref([
            'חדש',
            'טופל'
        ])
        
        const handle_update_status = async()=>{
            if(!selected_status.value){
                slide_pop_error('עליך לבחור סטטוס')
            }
            else if(selected_status.value == props.record.status){
                slide_pop_error(`אינך יכול לעדכן סטטוס ${selected_status.value} ל-${selected_status.value}`)
            }
            else{
                await projectFirestore.collection('Applications').doc('2l5VnaCyL99x0EhM7Gw6')
                .collection('forms').doc(props.record.uid)
                .set({
                    status:selected_status.value
                },{merge:true})
                emit('update',props.record.uid)
                alert('success','הצלחה',
                `סטטוס שונה בהצלחה ל-${selected_status.value}`)
            }
        }
        const handle_close = ()=>{
            emit('close')
        }
        return{
            handle_close,
            status_options,
            selected_status,
            handle_update_status
        }
    }
}
</script>

<style scoped>
    .setting{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .setting-dialog{
        width: 500px;
        height: 20%;
        background:#fff;
        border-radius: 10px;
    }
    .setting-dialog-header{
        position: relative;
        width: 100%;
        height: 40px;
        border-radius: 10px 10px 0 0;
        background:var(--success);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .close-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        background: var(--danger);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
        z-index: 6;
    }
    .setting-dialog-content{
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

</style>